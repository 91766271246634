import { createRouter, createWebHashHistory } from 'vue-router';
import Home from '../views/Home.vue';
import Solutions from '../views/Solutions.vue';
import Company from '../views/Company.vue';
import News from '../views/News.vue';
import Contact from '../views/Contact.vue';
import NewsDetail from '../views/NewsDetail.vue';

const routes = [
  { path: '/', name: 'Home', component: Home },
  { path: '/solutions', name: 'Solutions', component: Solutions },
  { path: '/company', name: 'Company', component: Company },
  { path: '/news', name: 'News', component: News },
  { path: '/contact', name: 'Contact', component: Contact },
  { path: '/newsDetail', name: 'newsDetail', component: NewsDetail },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 如果 savedPosition 存在，返回它，这样浏览器会滚动到保存的位置
    if (savedPosition) {
      return savedPosition;
    } else {
      // 否则，滚动到顶部
      return { top: 0 };
    }
  }
});

export default router;
