<template>
  <div class="home">
    <div class="bg">
      <div class="content">
        <h1>华云科技人工智能解决方案</h1>
        <p>面向教育、政务、医疗、企业的AI应用，助力AI+场景高效落地</p>
        <button class="learn-more">了解更多</button>
      </div>
    </div>
    <div class="main">
      <section class="solutions">
        <SolutionCard title="AI基础教育解决方案" subtitle="AI基础教育解决方案"
          description="人工智能，让教育回归本质，随着AI技术的不断发展，人类将变得更加自由，教育也将变得更加美好。我们将不断探索，致力于优化教育体验，让教育回归其真正的本质。"
          imageSrc="education.png" imageAlt="AI基础教育" :start="3" :end="6" :icons="educationData" />
      </section>

      <section class="solutions">
        <SolutionCard title="AI高职教解决方案" subtitle="AI高职教解决方案"
          description="基于华为盘古大模型结合教育数据训练的教育专属行业模型，向高职院校提供涵盖教学、教研、科研、评价、数字人、行政管理、资源沉淀、学校治理等方面的解决方案以及AI课程建设。"
          imageSrc="vocational.png" imageAlt="AI职业教育" :start="3" :end="5" :icons="vocationalData" />
      </section>


      <section class="solutions">
        <SolutionCard title="AI医疗解决方案" subtitle="AI医疗解决方案"
          description="华云天图通过融合先进的人工智能技术和深厚的医学理解，致力于提高医疗服务质量与效率，推动医患有效沟通，实现个性化和精准化治疗。其愿景是助力“健康中国”建设，打造智能高效的未来医疗体系。"
          imageSrc="treatment.png" imageAlt="AI医疗解决方案" :start="3" :end="4" :icons="treatmentData" />
      </section>

      <div class="banner">
        <div class="banner-content">
          <div class="title">开启AI场景应用新时代</div>
          <div class="buttons">
            <div class="btn">中小学</div>
            <div class="btn">职业院校</div>
            <div class="btn">教育局</div>
            <div class="btn">政府单位</div>
            <div class="btn">企业</div>
          </div>
        </div>
      </div>

      <!-- <section class="solutions">
        <SolutionCard title="AI教育管理解决方案" subtitle="AI教育管理解决方案"
          description="基于华为盘古大模型结合教育数据训练的教育专属行业模型，向教育局提供涵盖教学、教研、科研、培训、科创、评价、心理健康、行政管理、资源沉淀等方面的解决方案。"
          imageSrc="management.png" imageAlt="AI教育管理" :start="3" :end="6" :icons="managementData" />
      </section> -->

      <section class="solutions">
        <SolutionCard title="AI政务服务解决方案" subtitle="AI政务服务解决方案"
          description="基于华为盘古大模型结合教育数据训练的政务专属行业模型，向消防、民政等部门提供涵盖数据治理、模型建设以及智能办公、便民服务、智能问答、文案生成AI应用等方面的解决方案。"
          imageSrc="government.png" imageAlt="AI政务服务" :start="3" :end="6" :icons="governmentData" />
      </section>

      <section class="solutions">
        <SolutionCard title="AI企业应用解决方案" subtitle="AI企业应用解决方案"
          description="基于华为盘古大模型结合企业数据训练的企业专属行业模型，向企业提供涵盖数据治理、企业内训、智能客服、人事管理、智能问答、文案生成等方面的解决方案。"
          imageSrc="enterprise.png" imageAlt="AI企业应用" :start="3" :end="6" :icons="enterpriseData" />
      </section>

      <div class="banner-footer">
        <div class="banner-content">
          <img src="@/assets/home/banner_icon.png" alt="" />
          <h1 v-html="highlightText('华云天图AI场景应用解决方案', 5, 10)"></h1>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script setup>
import { ref } from 'vue';
import Footer from "@/components/Footer";
import SolutionCard from "@/components/SolutionCard";

const educationData = ref([
  {
    src: "education-icon1.png",
    alt: "AI教学",
    text: "AI教学",
    type: "image",
  },
  {
    src: "education-icon2.png",
    alt: "AI行政",
    text: "AI行政",
    type: "image",
  },
  {
    src: "education-icon3.png",
    alt: "AI教育资源",
    text: "AI教育资源",
    type: "image",
  },
  {
    src: "education-icon4.png",
    alt: "AI评价",
    text: "AI评价",
    type: "image",
  },
  {
    src: "education-icon5.png",
    alt: "AI学校治理",
    text: "AI学校治理",
    type: "image",
  },
  {
    src: "education-icon6.png",
    alt: "AI自适应学习",
    text: "AI自适应学习",
    type: "image",
  },
]);

const vocationalData = ref([
  {
    alt: "AI教学",
    text: "AI教学",
    type: "text",
  },
  {
    alt: "AI科研",
    text: "AI科研",
    type: "text",
  },
  {
    alt: "AI评价",
    text: "AI评价",
    type: "text",
  },
  {
    alt: "AI数字人",
    text: "AI数字人",
    type: "text",
  },
  {
    alt: "AI行政管理",
    text: "AI行政管理",
    type: "text",
  },
  {
    alt: "AI资源沉淀",
    text: "AI资源沉淀",
    type: "text",
  },
  {
    alt: "AI学校治理",
    text: "AI学校治理",
    type: "text",
  },
]);

const treatmentData= ref([
{
    alt: "个人医疗数字分身",
    text: "个人医疗数字分身",
    type: "text",
  },
  {
    alt: "医生智能体",
    text: "医生智能体",
    type: "text",
  },
  {
    alt: "电子病例",
    text: "电子病例",
    type: "text",
  },
  {
    alt: "家庭医生",
    text: "家庭医生",
    type: "text",
  },
])

const managementData = ref([
  {
    alt: "AI教学",
    text: "AI教学",
    type: "text",
  },
  {
    alt: "AI科研",
    text: "AI科研",
    type: "text",
  },
  {
    alt: "AI评价",
    text: "AI评价",
    type: "text",
  },
  {
    alt: "AI科创",
    text: "AI科创",
    type: "text",
  },
  {
    alt: "AI心理健康",
    text: "AI心理健康",
    type: "text",
  },
  {
    alt: "AI行政管理",
    text: "AI行政管理",
    type: "text",
  },
  {
    alt: "AI资源沉淀",
    text: "AI资源沉淀",
    type: "text",
  },
]);

const governmentData = ref([
  {
    src: "governmentData-icon1.png",
    alt: "AI办公",
    text: "AI办公",
    type: "image",
  },
  {
    src: "governmentData-icon2.png",
    alt: "AI便民服务",
    text: "AI便民服务",
    type: "image",
  },
  {
    src: "governmentData-icon3.png",
    alt: "AI智能问答",
    text: "AI智能问答",
    type: "image",
  },
  {
    src: "governmentData-icon4.png",
    alt: "AI文案生成",
    text: "AI文案生成",
    type: "image",
  },
]);

const enterpriseData = ref([
  {
    src: "enterprise-icon1.png",
    alt: "AI数据治理",
    text: "AI数据治理",
    type: "image",
  },
  {
    src: "enterprise-icon2.png",
    alt: "AI企业内训",
    text: "AI企业内训",
    type: "image",
  },
  {
    src: "enterprise-icon3.png",
    alt: "AI智能客服",
    text: "AI智能客服",
    type: "image",
  },
  {
    src: "enterprise-icon4.png",
    alt: "AI人事管理",
    text: "AI人事管理",
    type: "image",
  },
  {
    src: "enterprise-icon5.png",
    alt: "AI智能问答",
    text: "AI智能问答",
    type: "image",
  },
]);

const highlightText = (text, start, end) => {
  return (
    text.slice(0, start - 1) +
    '<span style="color: #c30d23;margin:auto">' +
    text.slice(start - 1, end) +
    "</span>" +
    text.slice(end)
  );
};
</script>


<style scoped lang="scss">
.home {
  width: 100%;

  .bg {
    width: 100%;
    height: 630px;
    background-image: url("@/assets/home/home_bg.png");
    background-size: cover;
    background-position: center;
    display: flex;
    padding-left: 250px;
    box-sizing: border-box;
    align-items: center;

    .content {
      text-align: left;
      color: #fff;

      h1 {
        font-size: 48px;
        font-weight: bold;
        margin-bottom: 20px;
      }

      p {
        font-size: 18px;
        margin-bottom: 30px;
      }

      .learn-more {
        background-color: #c71b24;
        color: #fff;
        border: none;
        padding: 10px 20px;
        font-size: 16px;
        cursor: pointer;
        border-radius: 30px;
      }
    }
  }

  .main {
    background-color: #f4f5f7;
    padding: 0 250px;

    .banner {
      background: url("@/assets/home/banner.png") no-repeat center center;
      background-size: cover;
      height: 400px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      color: #333;
      margin: 160px -250px 0 -250px;
      padding: 0px 300px;
    }

    .banner-content {
      text-align: left;
    }

    .banner-content .title {
      font-size: 38px;
      color: #3e3a39;
      margin-bottom: 20px;
    }

    .buttons {
      display: flex;
      justify-content: center;
      gap: 10px;
    }

    .buttons .btn {
      border: 1px solid #656568;
      padding: 6px 20px;
      color: #3e3a39;
      font-size: 14px;
      cursor: pointer;
      transition: background-color 0.3s, border-color 0.3s;
    }

    .buttons button:hover {
      background-color: #f0f0f0;
      border-color: #999;
    }

    .banner-footer {
      background: url("@/assets/home/banner_footer.png") no-repeat center center;
      background-size: cover;
      height: 170px !important;
      box-sizing: border-box;
      line-height: 130px;
      align-items: center;
      justify-content: center;
      color: #333;
      margin: 200px -250px 0 -250px;
      background-color: #c1c8e4;

      .banner-content {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 110px;
          height: 40px;
          margin-right: 28px;
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .bg {
      padding-left: 100px;

      .content {
        h1 {
          font-size: 36px;
        }

        p {
          font-size: 16px;
        }

        .learn-more {
          padding: 8px 16px;
          font-size: 14px;
        }
      }
    }

    .main {
      padding: 0 100px;

      .banner {
        margin: 160px -100px 0 -100px;
        padding: 0px 100px;
      }

      .banner-footer {
        margin: 200px -100px 0 -100px;
      }
    }
  }

  @media (max-width: 1080px) {
    .bg {
      padding-left: 50px;

      .content {
        h1 {
          font-size: 28px;
        }

        p {
          font-size: 14px;
        }

        .learn-more {
          padding: 6px 12px;
          font-size: 12px;
        }
      }
    }

    .main {
      padding: 0 50px;

      .banner {
        margin: 160px -50px 0 -50px;
        padding: 0px 50px;
      }

      .banner-footer {
        margin: 200px -50px 0 -50px;
      }
    }
  }
}
</style>
