import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import BaiduMap from 'vue3-baidu-map-gl';

const app = createApp(App);

app.use(BaiduMap, {
  ak: 'lRgQjrilH3MFDQOG29wjbXnzEUln9vaV' // 替换为你的百度地图 API Key
});

app.use(store);
app.use(router);
app.mount("#app");