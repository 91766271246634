import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

export default new Vuex.Store({
  state: {
    sections: []
  },
  mutations: {
    updateSections(state, newSections) {
      state.sections = newSections;
    }
  },
  getters: {
    sections: state => state.sections
  },
  plugins: [createPersistedState({
    storage: window.localStorage, 
    key: 'myVuexStore', 
    paths: ['sections']
  })]
});
