<template>
  <div class="solutions">
    <SectionComponent v-for="(section, index) in sections" :key="index" :data="section" />
    <Footer />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import Footer from "@/components/Footer";
import SectionComponent from "@/components/SectionComponent";

const store = useStore();

const sections = computed(() => store.getters.sections);
</script>
