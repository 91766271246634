<template>
  <footer class="footer">
    <div
      v-for="(record, index) in records"
      :key="index"
      class="footer-info"
    >
      <img src="@/assets/chinese_love.png" alt="Chinese Love">
      <a
        href="https://beian.miit.gov.cn/#/Integrated/index"
        style="color: inherit; text-decoration: none;"
      >
        <p>{{ record }}</p>
      </a>
    </div>
  </footer>
</template>

<script setup>
import { ref } from 'vue';

const records = ref([
  "粤ICP备2021152536号-1",
  "粤ICP备2021152536号-2",
  "粤ICP备2021152536号-3",
  "粤ICP备2021152536号-13",
  "粤ICP备2021152536号-14"
]);
</script>

<style scoped>
.footer {
  width: 100%;
  height: 30vh;
  background-image: url('@/assets/footer.png'); /* 替换为你的背景图片路径 */
  background-size: cover;
  background-position: center; /* 背景图片居中显示 */
  background-repeat: no-repeat; /* 防止背景图片重复 */
  background-color: #0c0808;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: flex-end; /* 垂直底部对齐 */
  padding-bottom: 28px; /* 底部内边距 */
}

.footer-info {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #fff;
  margin: 0 10px; /* 每个元素之间的间距 */
}

img {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
</style>
