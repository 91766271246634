<template>
  <div class="news">
    <div class="bg">
      <div class="content">
        <h1>华云科技人工智能解决方案</h1>
        <p>面向教育、政务、医疗、企业的AI应用，助力AI+场景高效落地</p>
        <button class="learn-more">了解更多</button>
      </div>
    </div>
    <div class="main">
      <div class="breadcrumb">
        <a href="/">首页</a>
        <span> &gt; </span>
        <span class="current">新闻资讯</span>
      </div>

      <div class="news-list">

        <h2>新闻动态</h2>
        <div class="news-item" v-for="(news, index) in newsList" :key="index" @click="handleDetail(index)">
            <div class="news-text">
              <h3>{{ news.title }}</h3>
              <p class="date">{{ news.date }}</p>
              <p>{{ news.content }}</p>
            </div>
            <div class="news-image">
              <img :src="news.img" alt="新闻图片">
            </div>

        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script setup>
import { ref } from 'vue';
import Footer from "@/components/Footer";
import { useRouter } from 'vue-router';

const router = useRouter();

const imageUrl = require('@/assets/news/news_img.png');
const imageUrl1 = require('@/assets/news/news1.jpeg');
const imageUrl2 = require('@/assets/news/news2.jpeg');
const imageUrl3 = require('@/assets/news/news3.webp');
const imageUrl4 = require('@/assets/news/news4.webp');
const imageUrl5 = require('@/assets/news/news5.webp');
const imageUrl6 = require('@/assets/news/news6.webp');
const imageUrl7 = require('@/assets/news/news7.webp');
const imageUrl8 = require('@/assets/news/news8.webp');
const imageUrl9 = require('@/assets/news/news9.webp');

const handleDetail=(index)=>{
  router.push(`/newsDetail?index=${index}`)
}

const newsList = ref([
  {
    title: '苏州大学新教育研究院人工智能未来教育研究所正式成立',
    date: '华云 2023年12月28日',
    content: '12月28日上午，苏州大学新教育研究院人工智能未来教育研究所正式成立。华为云全球生态部总裁康宁、华为云教育解决方案总经理唐国军、 华为云云学堂总经理李亮、华为云江苏副总经理郭剑、华为云生态伙伴发展总监王静涛;中陶会新教育专委会理事长许新海、新 教育研究院院长李镇西、新教育研究院办公室主任杜涛、苏州大学新教育研究院副院长郭小月、新网师执行主任郝晓东;深圳华 云科技研发有限公司董事长肖平、总经理李妙丽等参加仪式。',
    img: imageUrl
  },
  {
    title: '这个生成式AI作业系统好神奇！小小作业本里的“智能变革',
    date: '华云 2024年05月12日',
    content: `减作业是减负最直接也是最重要的一个指标，“双减”之下作业如何“减量提质”？怎样才能做到“减负不减分”？单科老师如何辅导全学段作业？如何有效减少家长负担，提升家长满意度？宝安区西湾小学率先试运行国内首个AIGC生成式人工智能作业系统已有半年多时间，这套“AI作业系统”显然已经获得了老师孩子们的认可。据介绍，这套诞生于深圳的人工智能作业系统行将从西湾小学走向深圳乃至全国。我们习以为常的小小的作业本，可能迎来一场教育的“智能变革”。`,
    img: imageUrl1
  },
  {
    title: '【人工智能】王红部长受邀在全国基础教育管理服务平台应用培训会上作《人工智能：让教育回归真正的教育》专题讲座',
    date: '华云 2024年7月20日',
    content: '7月10日至12日，2024年全国基础教育管理服务平台应用培训班在华南师范大学汕尾校区举行。本次培训班由教育部基础教育司指导、教育部教育管理信息中心主办、华南师范大学承办，旨在加快推进全国基础教育管理服务平台全面应用，提升学校教育信息化管理水平，赋能基础教育高质量发展。教育部基础教育司、教育部教育管理信息中心、华南师范大学、汕尾市教育局有关领导以及来自全国各省（直辖市、自治区）省级教育行政部门、部分县区教育局干部、中小学老师、企业代表等约200人参会。',
    img: imageUrl3

  },
  {
    title: '人才是技术发展的源头活水，华为云携手伙伴为人才培养探新路！',
    date: '华云 2024年6月27日',
    content: '在刚刚过去的华为开发者大会2024上，丰富多样的主题演讲、峰会、专题论坛，以及数百场面向开发者的特色体验活动，都成为最近互联网从业者高频讨论的话题。其中，垂直教育领域的“云商店助力智慧教育数字化转型 探索人工智能与教育信息化融合创新”专题论坛，邀约教育领域专家、学校、教育行业企业，共同就教育智能化升级展开了积极讨论。',
    img: imageUrl9

  },
]);
</script>

<style scoped lang="scss">
.news {
  width: 100%;


  .bg {
    width: 100%;
    height: 630px;
    background-image: url("@/assets/news/bg.png");
    background-size: cover;
    background-position: center;
    display: flex;
    padding-left: 250px;
    box-sizing: border-box;
    align-items: center;

    .content {
      text-align: left;
      color: #fff;

      h1 {
        font-size: 48px;
        font-weight: bold;
        margin-bottom: 20px;
      }

      p {
        font-size: 18px;
        margin-bottom: 30px;
      }

      .learn-more {
        background-color: #c71b24;
        color: #fff;
        border: none;
        padding: 10px 20px;
        font-size: 16px;
        cursor: pointer;
        border-radius: 30px;
      }
    }
  }

  .main {
    background-color: #f4f5f7;
    padding: 0 250px;

    .breadcrumb {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #636262;
      height: 160px;
      padding-top: 50px;
      box-sizing: border-box;


      a {
        text-decoration: none;
        color: #636262;
      }

      span {
        margin: 0 15px;
      }

      .current {
        padding: 0;
        margin: 0;
      }
    }



    .news-list {
      width: 100%;
      margin-top: 20px;

      h2 {
        text-align: center;
        margin-bottom: 20px;
        font-size: 2rem;
        font-weight: bold;
        margin-bottom: 130px;
      }

      .news-item {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-bottom: 60px;
        border-bottom: 1px solid #e2e3e3;

        .news-text {
          flex: 1;
          margin-right: 20px;
          cursor: pointer;

          h3 {
            font-size: 1.5rem;
            margin-bottom: 10px;
            font-weight: 400;
          }

          .date {
            font-size: 1rem;
            color: #888;
            margin-bottom: 30px;
          }

          p {
            font-size: 1rem;
            line-height: 2;
            color: #565352;
          }
        }

        .news-image {
          cursor: pointer;
          width: 350px;
          height: 100%;
          overflow: hidden;
          margin-left: 90px;
          padding-top: 24px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }

  @media (max-width: 1440px) {
    .bg {
      padding-left: 150px;

      .content {
        h1 {
          font-size: 40px;
        }

        p {
          font-size: 16px;
        }
      }
    }

    .main {
      padding: 0 150px;

      .news-list {
        .news-item {
          .news-image {
            width: 300px;
            margin-left: 60px;
          }
        }
      }
    }
  }

  @media (max-width: 1280px) {
    .bg {
      padding-left: 100px;

      .content {
        h1 {
          font-size: 36px;
        }

        p {
          font-size: 14px;
        }
      }
    }

    .main {
      padding: 0 100px;

      .news-list {
        .news-item {
          .news-image {
            width: 250px;
            margin-left: 40px;
          }
        }
      }
    }
  }

  @media (max-width: 1080px) {
    .bg {
      padding-left: 50px;

      .content {
        h1 {
          font-size: 32px;
        }

        p {
          font-size: 12px;
        }
      }
    }

    .main {
      padding: 0 50px;

      .news-list {
        .news-item {
          flex-direction: column;

          .news-text {
            margin-right: 0;
            margin-bottom: 20px;
          }

          .news-image {
            width: 100%;
            margin-left: 0;
          }
        }
      }
    }
  }
}
</style>
